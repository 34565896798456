import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import StoresList from '../components/storesList'
import Container from '../components/container'
import RegionsList from '../components/regionsList'

class RegionTemplate extends React.Component {
  render() {
    const stores = this.props.pageContext?.stores
    const storeBasePathName = this.props.pageContext?.storeBasePathName
    const name = this.props.pageContext?.name
    const regions = this.props.data.allContentfulRegion.nodes

    return (
      <Container>
        <Seo title={name} description={`Découvrer nos produits dans la région de ${name}`} />
        <RegionsList storeBasePathName={storeBasePathName} regions={regions} />
        <StoresList stores={stores} storeBasePathName={storeBasePathName} />
      </Container>
    )
  }
}

export default RegionTemplate

export const pageQuery = graphql`
  query RegionIndexQuery {
    allContentfulRegion {
      nodes {
        name
        slug
        stores {
          slug
          name
          longitudeLatitude {
            lat
            lon
          }
        }
      }
    }
  }
`
